/*----------- General Styles -------------*/

#demo-canvas {
    position: absolute;
    top: 0;
    z-index: -1;
}
body {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAACXU8ZrAAAAgklEQVQYVxXMMQ6CQABE0T+wCGK0sUFDYqkX8By23v8A2gBC2N0x9C9P7a52H+Ah6OfIOZuDMwOBLzABujSN7za3GDkmUyP2mBHxIfCT0bOpfV0TXYbSsMqEbTCMCsxk9Koqn6JpbYwYMEklM2IxLAXoXQa3SRSYVduwwYJJIgER+ANKGDqgLtl6vgAAAABJRU5ErkJggg==");
    background-size: cover;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
    color: #fff;
    width: 100vw;
    height: 100vh;
    margin: 0;
    position: absolute;
    font-size: 14px;
    overflow-x: hidden;
}
select,
input {
    border: 2px solid #fff;
}
input,
select,
select:focus,
select:active,
button:focus,
button:active,
input:active,
input:focus,
input:invalid {
    outline: none;
    box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    border: 0;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    /*-webkit-appearance: none;*/
    /*margin: 0;*/
}
input[type=number] {
    /*-moz-appearance:textfield;*/
}
input[name=search]:-webkit-autofill,
input[name=search]:-webkit-autofill:hover,
input[name=search]:-webkit-autofill:focus,
input[name=search]:-webkit-autofill:active {
    -webkit-text-fill-color: #fff !important;
    caret-color: #fff;
}

.card {
    width: auto;
    margin: 20px;
}
.card .particular-lab-control{
    opacity: 0.6;
    transition: 0.3s;
}
.card .particular-lab-control:hover {
    cursor: pointer;
    opacity: 1;
}
.change-password-component .password-input,
.sign-in-component .password-input,
.sign-in-seminar-component .password-input{
    position: relative;
}
.change-password-component .password-input button.show-password-button,
.sign-in-seminar-component .password-input button.show-password-button,
.sign-in-component .password-input button.show-password-button{
    position: absolute !important;
    right: 0;
    top: -4px;
    /* bottom: -8px; */
}
.change-password-component .password-input button.show-password-button span,
.sign-in-seminar-component .password-input button.show-password-button span,
.sign-in-component .password-input button.show-password-button span {
    color: #060000 !important;
}
.change-password-component .password-input button.show-password-button svg,
.sign-in-seminar-component .password-input button.show-password-button svg,
.sign-in-component .password-input button.show-password-button svg {
    color: #060000 !important;
}
.first-step-component .error-container,
.second-step-component .error-container,
.forgot-password-component .error-container,
.change-password-component .error-container,
.sign-in-component .error-container,
.modal .error-container {
    text-align: center;
    font-size: 13px;
}
.first-step-component .error-container span,
.second-step-component .error-container span,
.modal.drop-users-file-modal .no-data,
.forgot-password-component .error-container span,
.change-password-component .error-container span,
.sign-in-component .error-container span,
.modal .error-container span {
    /*transition: 0.3s;*/
    color: #ff6b66;
    letter-spacing: 1px;
}
.forgot-password-component .error-container span.hide,
.change-password-component .error-container span.hide,
.sign-in-component .error-container span.hide,
.modal .error-container span.hide {
    opacity: 0;
}
.not-match,
.same-name {
    border: 2px solid red;
}
.first-step-component form input.invalid:invalid,
.second-step-component form input.invalid:invalid,
.forgot-password-component input.invalid:invalid,
.change-password-component input.invalid:invalid,
.sign-in-component input.invalid:invalid,
.modal input.invalid:invalid {
    border: 2px solid #ff2a27;
}
.table-container table input[type=button] {
    margin: 0 20px 0 0;
    height: 30px;
    width: auto;
}
.modal.show-users-modal tr.no-data td,
.no-data,
.labs-management .no-labs {
    text-align: center;
    padding: 20px 0;
}
.success-add-group-labs-modal .entity-name-action,
.notification-list-modal .entity-name-action {
    margin-bottom: 20px;
}
.notification-list-modal .data-list,
.success-add-group-labs-modal .data-list {
    padding: 0 !important;
}
.notification-list-modal span.delete-labs {
    display: block;
    margin-bottom: 20px;
}
.input-dropzone{
    color: #fff;
    width: 130px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
}
.input-dropzone:hover{
    background-color: rgba(255, 255, 255, 0.1);
}

/*----------- Labs tables -------------*/

.table-row {
    transition: 0.3s;
}
.table-row:hover {
    background-color: #ffffff1f
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.no-data-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}
.no-data-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
}
.labs-management .control-buttons-block button {
    border-radius: 10px !important;
}
.labs-management .actions .controls i {
    font-size: 20px;
}
.labs-management .card{
    font-size: 14px;
}
.user-management .add-user-block,
.labs-management .control-buttons-block {
    margin: 25px;
    position: relative;
}
.labs-management .control-buttons-block select {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-management .right-actions-block,
.labs-management .right-actions-block {
    top: -7px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: flex-end;
}
.labs-management .card .actions {

    position: relative;
}
.labs-management .card .actions {
    text-align: center;
}
.labs-management .control-buttons-block img {
    height: 100px;
    margin: -30px 20px -30px auto;
    transition: 0.3s;
}
.labs-management .control-buttons-block img.disabled {
    opacity: 0;
}

.labs-management .card .actions img,
.labs-management img {
    width: 100%;
}
.lab-details .progress-bar.lab-instance {
    width: 55px !important;
    height: 55px;
}
.labs-management .card .actions .controls {
    min-width: 100px;
    width: min-content;
    display: inline-block;
}
.labs-management .card .actions .prelodaer {
    opacity: 1 !important;
    width: min-content;
    display: inline-block;
}
.card .actions.disabled img {
    opacity: 1;
    display: inline-block;
}
.card .actions img.disabled {
    opacity: 0;
    display: none;
}
.labs-management .control-buttons-block {
    height: 50px;
    display: flex;
    align-items: center;
}
.labs-management .control-buttons-block .group-filter .label {
    position: relative;
    bottom: 23px;
}
.labs-management .control-buttons-block .user-filter .username-label {
    position: relative;
    bottom: 10px;
}
.labs-management .control-buttons-block .group-filter button,
.labs-management .control-buttons-block .user-filter button {
    position: relative;
    bottom: 15px;
}
.labs-management .status-filter {
    margin: 0 20px 23px;
}
.labs-management .user-filter {
    margin: 11px 0 0;
}
.labs-management .card .actions .particular-lab-control.disabled,
.labs-management .card .actions.disabled * { /* '*' means for all children*/
    cursor: not-allowed;
    pointer-events: none;
}
.labs-management .table-parts-container .MuiTableCell-head.actions-column .inner {
    padding-left: 20px;
}
.labs-management .table-parts-container .MuiTableCell-head.status-actions-column .inner {
    padding-left: 25px;
}

/* --- User/Group/Lab management --- */

.lab-templates-management .table-container,
.group-management .table-container,
.user-management .table-container,
.reports-management .table-container,
.user-history .table-container,
.seminar-history-page .table-container,
.labs-management .table-container {
    height: 60vh;
    overflow-y: auto;
}
.seminar-labs-list,
.student-seminar-labs-list,
.seminar-users-list,
.seminar-list,
.lab-templates-management,
.labs-management,
.group-management,
.user-management,
.reports-management,
.seminar-history-page,
.user-history {
    height: calc(100vh - 50px);
    width: calc(100% - 210px);
    background-color: transparent;
}
.lab-templates-management .add-lab-template-block,
.group-management .add-group-block,
.user-management .add-user-block,
.seminar-history-page .filter-history-block,
.user-history .filter-history-block,
.seminar-list .control-buttons-block,
.seminar-users-page .control-buttons-block,
.seminar-labs-list .control-buttons-block,
.student-seminar-labs-list .control-buttons-block,
.seminar-users-list .control-buttons-block {
    height: 50px;
    margin: 25px;
}
.lab-templates-management .add-lab-template-block,
.group-management .add-group-block,
.seminar-list .control-buttons-block,
.seminar-list .control-buttons-block,
.seminar-labs-list .control-buttons-block,
.student-seminar-labs-list .control-buttons-block,
.seminar-users-list .control-buttons-block {
    display: flex;
    align-items: center;
}
.dashboard {
    display: flex;
    flex-direction: column;
}
.dashboard .horizontal-stuff {
    display: flex;
}

/* .modal-style > div > div > div {
    background-color: rgb(22, 0, 0) !important;
    border: 1px solid rgba(255, 255, 255, 1);
} */
div[role=presentation]{
    /*max-height: 200px !important;*/
}
.drop-down-general div[role=menu] {
    padding: 5px 0 !important;
}

.select-template-modal .checkbox-column,
.labs-management .checkbox-column,
.labs-management .id-column {
    width: 50px;
}
.labs-management .lab-type-column {
    white-space: unset !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.labs-management table tr td button div span, .labs-management .links-button-block button.links-button span {
    font-size: 1.1em !important;
}

.labs-management .group-name-column {
    overflow: hidden;
    text-overflow: ellipsis;
}

.labs-management .last-action-column {
    width: 100px;
}
.labs-management .resource-group-column {
    width: 160px;
    word-break: break-word;
}

.labs-management .lab-type-column,
.labs-management .actions-column {
    width: 130px;
}
.labs-management .timer-count-column {
    width: 140px;
}
.labs-management .version-column {
    width: 45px;
}
.labs-management .lab-details .reset-timer-button span,
.labs-management .timer-column .reset-timer-button span {
    font-size: 14px !important;
}
.student-dashboard .labs-management .status-actions-column {
    width: 260px;
    max-width: 260px;
    min-width: 260px;
}
.labs-management .version-select {
    width: 205px;
    max-width: 205px;
    min-width: 205px;
}
.labs-management .status-actions-column {
    width: 240px;
    max-width: 240px;
    min-width: 240px;
}
.labs-management .status-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.labs-management .status-actions-container span {
    margin-right: 20px;
}
.labs-management .status-actions-drop-down-menu {
    right: 0;
    margin: 0 75px 0 10px;
    font-size: 14px;
    bottom: 13px;
}
.labs-management .ip-column,
.labs-management .links-column {
    width: 175px;
}
.labs-management .group-name-column {
    width: 100px;
}
.labs-management .username-column {
    width: 120px;
}
.labs-management .student-status-actions-drop-down-menu {
    position: absolute !important;
    right: 0;
    margin: 0 75px 0 auto;
    font-size: 14px;
    width: 195px !important;
}
.table-parts-container > div {
    overflow: hidden !important;
}
.general-table-container > div {
    overflow: hidden !important;
}

/* .add-group-modal { */
    /* z-index: 1501 !important; */
/* } */
.lab-template-drop-down-menu {
    width: 350px !important;
}
.notification-list-modal > div > div > div > div:nth-child(2) {
    padding-bottom: 0 !important;
}

img.computer{
    border: 2px solid transparent;
}
img.active {
    border: 2px solid orange;
    -webkit-box-shadow: 0 0 25px 0 rgba(255,165,0,1);
    -moz-box-shadow: 0 0 25px 0 rgba(255,165,0,1);
    box-shadow: 0 0 25px 0 rgba(255,165,0,1);
}

.labs-management .links-column.lab-links button, .labs-management .links-button-block button.links-button{
    height: 21px !important;
    line-height: 21px !important;
    min-width: 45px !important;
    display: block !important;
    margin: 1px !important;
}

.labs-management a{
    text-decoration: none !important;
}

.list-item .buttons-container button{
    height: 21px !important;
    line-height: 21px !important;
    min-width: 45px !important;
    /* display: block !important; */
    margin: 3px 0 !important;
}

.general-table-container .links-column button{
    height: 21px !important;
    line-height: 21px !important;
    min-width: 45px !important;
    /* display: block !important; */
    margin: 3px 0 !important;
}

.labs-management .links-column{
    width: 80px;
}
.labs-management .links-column.lab-links a button.links-button span{
    font-size: 12px !important;
}

.labs-management .links-column.lab-links a button.links-button span{
    font-size: 12px !important;
}

.history-management .table-size-block,
.general-table-container .table-size-block,
.labs-management .table-size-block,
.user-management .users-table-body .table-size-block {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.time-frame-modal .dates-container,
.time-frame-modal .times-container{
    display: flex;
    justify-content: space-evenly;
}

.time-frame-modal .dates-container .date-picker-block,
.time-frame-modal .times-container .date-picker-block {
    display: flex;
    align-items: center;
}

.time-frame-modal .MuiCheckbox-root{
    color: #fff;
}
/* ====== USER ASSIGNING ====== */

.user-management .table-parts-container .user-assigning-id-column {
    width: 50px;
}
.user-management .table-parts-container .user-assigning-actions-column .inner {
    padding-left: 22px;
}

.user-management .add-user-block .select-group-label {
    margin-right: 20px;
    font-size: 14px;
}
.user-management .add-user-block {
    display: flex;
    align-items: center;
}

/* --- custom scroll bar --- */

::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    position: fixed;
}

/* !* Track *! */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* !* Handle *! */
::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

/* !* Handle on hover *! */
::-webkit-scrollbar-thumb:hover {
    background: #b30000;
    z-index: 500;
}

/* --- */

/* lab-details page */

.lab-details .instances-container {
    display: flex;
    margin: 15px 25px 25px;
}
.lab-details .lab-table {
    margin: 25px 25px 15px;
}
.lab-details .instances-container .paper.thumbnail-block,
.lab-details .instances-container .subnet-table {
    /*width: 400px;*/
    /*margin-left: 20px;*/
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 15px;
}
.lab-details .instances-container .paper.thumbnail-block{
    margin-top: 20px;
}
.lab-details .instances-container .paper.thumbnail-block img{
    display: block;
    width: 100%;
    max-height: 240px;
    margin: 0;
}
.lab-details .instances-container .subnet-table .subnet-block {
    /*display: flex;*/
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    margin: 0 10px;
    /*background-color: #b300002e;*/
    /*overflow-y: auto;*/
    /*max-height: calc(100vh - 350px);*/
}
.lab-details .instances-container .subnet-table .limitation-block {
    /*display: flex;*/
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin: 10px;
    /*background-color: #b300002e;*/
    /*overflow-y: auto;*/
    max-height: calc(100vh - 350px);
}
.lab-details .instances-container .subnet-table .subnet-block .list-item {
    width: 110px;
    font-size: 14px !important;
}
.lab-details .instances-container .subnet-table .subnet-block .data-container {
    display: flex;
}
.lab-details .instances-container .subnet-table .subnet-block .list-item.id-item {
    width: 25px;
}
.lab-details .instances-container .no-mask {
    background-color: transparent;
    margin: 10px;
    padding: 10px;
}
.lab-details .instances-container .paper {
    /*width: calc(75% - 80px);*/
    height: max-content;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*max-height: calc(100vh - 265px);*/
    overflow-y: auto;
}
.lab-details .instance-block {
    display: flex;
    margin: 10px;
    width: calc(50% - 20px);
    padding: 10px 0;
}
.lab-details .instance-block.stopped-instance {
    background-color: rgba(191, 81, 7, 0.11);
}
.lab-details .instance-block.running-instance {
    background-color: rgba(255, 181, 0, 0.14);
}
.lab-details .instance-block.transition-instance {
    background-color: rgba(30, 255, 0, 0.14);
}
.lab-details .instance-block .left-part {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 130px;
}
.lab-details .instance-block .left-part .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 80px;
}
.lab-details .instance-block .right-part {
    display: flex;
    width: 75%;
}
.lab-details .list {
    padding: 0 !important;
}
.lab-details .list .list-item {
    font-size: 12px !important;
    padding: 5px !important;
}
.lab-details .list .list-item.first-item {
    border-bottom: 1px solid white;
}
.lab-details .list .list-item.timer-item {
    padding: 0 0 10px 5px !important;
}

.lab-details .subnet-block .data-container.first-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.31);
}

.lab-details .instance-block .left-part img.computer {
    width: 90px;
    margin: 0;
}
.lab-details .no-instances {
    margin: 10px;
}
.labs-management.lab-details .id-column {
    width: 40px;
}
.labs-management.lab-details .status-actions-col .inner,
.labs-management.lab-details .version-select .inner,
.labs-management.lab-details .status-actions-column .inner {
    padding-left: 23px;
}
.labs-management.lab-details .right-list .buttons-container,
.labs-management.lab-details .right-list .buttons-container button{
    display: inline-block !important;
    padding: 0 2px;
}

.lab-details .switch-button {
    background-color: #570C0F;
    color: #ffffff;
    font-weight: bold;
    width: 100px;
    border-radius: 30px;
    border: none;
    margin: 0 1px;
    padding: 0 !important;
    line-height: 25px;
}

.lab-details .switch-button.active {
    color: #570C0F;
    background-color: #ffffff;
    border: none;
}

/*.copy-popover{*/
    /*!*position: absolute;*!*/
    /*right: 40px;*/
    /*color: #fff !important;*/
    /*padding: 2px 8px !important;*/
/*}*/
.lab-details .lab-table .ip-block span{
    font-size: 12px !important;
}

.copy-to-clipboard-button{
    margin-left: 5px !important;
    font-size: 12px !important;
}

.copy-to-clipboard-info{
    border: 1px solid #fbe600;
    position: absolute;
    top: 49px;
    left: calc(50% - 152px);
    padding: 5px 20px;
    color: #fbe600 !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.copy-to-clipboard-info p {
    margin: 0;
}

.timer-column .countdown-component{
    /*display: block;*/
}

/* drop-down-general */

div[role=presentation] {
    /* position: relative; */
    /*top: 50px;*/
}
.drop-down-general > div > div {
    opacity: 1 !important;
}
.drop-down-general > div > div:nth-child(2) {
    top: 0 !important;
}

/* User Table Stuff */
.user-management .maintenance-column {
    width: 200px;
    box-sizing: border-box;
}
.user-management .checkbox-column {
    width: 50px;
    box-sizing: border-box;
}
.user-management .id-column {
    width: 75px;
    box-sizing: border-box;
}
.user-management .username-column {
    width: 200px;
    box-sizing: border-box;
}
.user-management .group-column {
    width: 200px;
    box-sizing: border-box;
}
.user-management .email-column {
    width: 200px;
    box-sizing: border-box;
    overflow-x: hidden;
}
.user-management .role-column,
.user-management .last-login-column {
    box-sizing: border-box;
    width: 200px;
}
.user-management .actions-column {
    width: 300px;
    box-sizing: border-box;
}
.user-management .table-row .custom-drop-down .items-list-container {
    left: 0;
}
.user-management .users-table-body .column {
    white-space: nowrap;
    text-overflow: ellipsis;
    /*overflow: hidden;*/
}
.general-table-container .pagination,
.user-management .pagination,
.user-history .pagination,
.seminar-history-page .pagination,
.labs-management .pagination {
    text-align: center;
}

.reports-table .pagination-block {
    display: flex;
    justify-content: flex-end;
}
.reports-table .pagination-reports {
    flex: 1;
}
.reports-table .table-size-block {
    position: initial;
}

.show-users-modal .break-words,
.lab-details .break-words,
.notification-list-modal .break-words,
.history-detail-modal .break-words {
    padding: 10px;
    white-space: initial !important;
    word-wrap: break-word;

}

/* fixed react-custom-scrollbars-2 lib styles */
.show-users-modal .table-block  > div:first-child > div:first-child,
.scroll-container > div:nth-child(2) > div:first-child,
.modals-tables-container > div:nth-child(2) > div:first-child,
.user-management .users-table-body > div:first-child > div:first-child,
.table-parts-container > div:nth-child(2) > div:first-child,
.history-management > div:nth-child(2) > div:first-child {
    overflow-x: hidden !important;
    padding-right: 57px !important;
    margin-right: -74px !important;
    padding-bottom: 17px !important;
}
@supports (-moz-appearance:none) {
    .show-users-modal .table-block  > div:first-child > div:first-child,
    .scroll-container > div:nth-child(2) > div:first-child,
    .modals-tables-container > div:nth-child(2) > div:first-child,
    .user-management .users-table-body > div:first-child > div:first-child,
    .table-parts-container > div:nth-child(2) > div:first-child,
    .history-management > div:nth-child(2) > div:first-child {
        margin-bottom: 0 !important;
    }
}

.subscription-name-column {
    width: 250px;
}

/* ====== SIGN IN PAGES ====== */

.sign-in-component form,
.first-step-component form,
.second-step-component form {
    width: 300px;
    padding: 50px 50px 30px;
    position: relative;
}
.sign-in-component form input[type=password],
.sign-in-component form input[type=text],
.sign-in-component form input[type=email],
.first-step-component form input,
.second-step-component form input {
    font-size: 16px;
    width: 270px;
    padding: 5px 10px;
}
.sign-in-component form p,
.first-step-component form p,
.second-step-component form p {
    text-align: center;
    width: 100%;
}
.sign-in-component form input[type=email]:invalid,
.first-step-component form input:invalid,
.second-step-component form input:invalid,
.sign-in-component form input[type=password]:invalid{
    box-shadow: none;
}
.sign-in-component form .submit-button,
.first-step-component form .submit-button,
.second-step-component form .submit-button {
    width: 290px;
    padding: 10px;
}
.sign-in-component form input[type=submit],
.first-step-component form input,
.second-step-component form input,
.sign-in-component form input[type=password],
.sign-in-component form input[type=email]{
    font-size: 16px;
}
.sign-in-component form .error-block,
.first-step-component form .error-block,
.second-step-component form .error-block {
    font-size: 14px;
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f46363;
    transition: 0.2s;
}
.sign-in-component form .error-container,
.first-step-component form .error-container,
.second-step-component form .error-container {
    font-size: 12px;
    height: 10px;
    margin: 10px 0 25px 0;
}

/* ====== SEMINAR LIST ====== */

.seminar-list .array-data-container {
    padding: 5px 0;
}
.seminar-list .array-data-container .column {
    padding: 0 !important;
}
.seminar-list .header-column,
.seminar-list .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.seminar-list .id-column {
    width: 50px;
}
.seminar-list .date-column {
    width: 110px;
}
.seminar-list .subscription-column {
    width: 160px;
}
.seminar-list .lab-template-column {
    min-width: 200px;
}
.seminar-list .state-column {
    width: 80px;
}
.seminar-list .users-column {
    width: 95px;
}
.seminar-list .timeout-column {
    width: 95px;
}
.seminar-list .actions-column {
    width: 140px;
}
.seminar-list .actions-column.ga {
    width: 100px;
}
.seminar-list .actions-column .inner {
    padding-left: 5px;
}
.seminar-list .actions-column i {
    transition: .3s;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.70);
}
.seminar-list .actions-column i.disabled,
.seminar-list .actions-column i.disabled:hover,
.seminar-list .actions-column i.end.disabled:hover {
    color: rgba(255, 255, 255, 0.30);
    pointer-events: none;
    cursor: auto;
}
.seminar-list .actions-column i:hover {
    color: white;
}
.seminar-list .actions-column i.end,
.seminar-list .actions-column i.labs,
.seminar-list .actions-column i.assign,
.seminar-list .actions-column i.edit {
    margin-left: 5px;
}
.seminar-list .actions-column i.delete {
    font-size: 26px
}
.seminar-list .actions-column i.end:hover,
.seminar-list .actions-column i.delete:hover {
    color: rgba(255, 0, 0, 0.8);
}

/* ====== SEMINAR USERS ====== */

.seminar-users-list .header-column,
.seminar-users-list .column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.seminar-users-page .checkbox-column,
.seminar-users-list .checkbox-column {
    width: 50px;
}
.seminar-users-page .id-column,
.seminar-users-list .id-column {
    width: 40px;
}
.seminar-users-page .name-column,
.seminar-users-list .name-column {
    max-width: 250px;
    min-width: 150px;
}
.seminar-users-page .ip-column,
.seminar-users-list .last-sign-in-date-column,
.seminar-users-list .ip-column {
    width: 115px;
}
.seminar-users-page .join-date-column,
.seminar-users-list .join-date-column {
    width: 100px;
}
.seminar-users-page .email-column,
.seminar-users-list .email-column {
    max-width: 350px;
    min-width: 350px;
    overflow: hidden;
}
.seminar-users-page .actions-column,
.seminar-users-list .actions-column {
    width: 70px;
}
.seminar-users-list .actions-column.ga {
    width: 60px;
}
.seminar-users-list .actions-column .inner {
    padding-left: 5px;
}
.seminar-users-page .actions-column i,
.seminar-users-list .actions-column i {
    transition: .3s;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.70);
}
.seminar-users-page .actions-column i:hover,
.seminar-users-list .actions-column i:hover {
    color: white;
}
.seminar-users-list .actions-column i.reset {
    margin-left: 5px;
}
.seminar-users-list .actions-column i.delete {
    font-size: 26px
}
.seminar-users-page .actions-column i.delete:hover,
.seminar-users-list .actions-column i.delete:hover {
    color: rgba(255, 0, 0, 0.8);
}

/* ====== ADD EDIT SEMINAR MODALS ====== */

.add-edit-seminar-modals .papers-block {
    display: flex;
}
.add-edit-seminar-modals .papers-block .filed-container {
    height: 85px;
}
.add-edit-seminar-modals .dates-container {
    margin-top: 24px;
}
.add-edit-seminar-modals .times-container {
    margin-top: 32px;
}
.add-edit-seminar-modals .dates-container,
.add-edit-seminar-modals .times-container {
    display: flex;
    justify-content: space-between;
}
.add-edit-seminar-modals .dates-container .date-picker-block,
.add-edit-seminar-modals .times-container .date-picker-block {
    display: flex;
    align-items: center;
}
.add-edit-seminar-modals .dates-container .date-picker-block span,
.add-edit-seminar-modals .times-container .date-picker-block span {
    font-size: 14px;
}
.add-edit-seminar-modals .dates-container .date-picker-block .date-picker,
.add-edit-seminar-modals .times-container .date-picker-block .time-picker {
    margin-left: 5px;
}
.add-edit-seminar-modals .date-picker div,
.add-edit-seminar-modals .time-picker input {
    cursor: pointer !important;
}
.add-edit-seminar-modals .time-picker input,
.add-edit-seminar-modals .date-picker div input {
    text-align: center !important;
}
.add-edit-seminar-modals .drop-down-container {
    height: 85px;
}
.add-edit-seminar-modals .error-message {
    font-size: 12px;
    line-height: 12px;
    color: rgb(244, 67, 54);
    position: relative;
    top: -10px;
    left: 2px;
}
.add-edit-seminar-modals .date-wrapper .error-message {
    top: -5px;
}
.add-edit-seminar-modals .control-buttons-block {
    text-align: right;
    margin-top: 10px;
}
.add-edit-seminar-modals .date-wrapper {
    height: 65px;
}
.add-edit-seminar-modals .enabled-toggle > input[type="checkbox"] {
    width: 45px !important;
    left: 89% !important;
}

/* ====== SEMINAR LABS ====== */

.seminar-users-page .seminar-filter .label{
    position: relative;
    /* bottom: 20px; */
}

.seminar-labs-list .seminar-filter .label {
    position: relative;
    /* bottom: 15px; */
}

.seminar-labs-list .no-data-table-container.no-seminars {
    height: 65vh;
}
.seminar-labs-list.labs-management .username-column {
    width: 250px;
}

.button-hover {
    cursor: pointer;
}

.seminar-users-page i {
    cursor: pointer;
}

/* ====== REPORTS MANAGEMENT ====== */

.reports-management .reports-filters {
    display: flex;
    align-items: center;
    align-items: flex-start;
    /*height: 50px;*/
    margin: 25px;
}

.reports-management .reports-filters .right-part{
    padding-top: 3px;
}
.reports-management .reports-filters .right-part span {
    min-width: 70px;
    display: inline-block;
}

.reports-management .reports-filters .summary {
    display: flex;
    align-items: center;
}

.reports-management .reports-filters .draggable {
    cursor: grab;
}

.reports-table {
    width: auto;
    margin: 25px 25px 0 25px;
}

.reports-table .table-row{
    height: 48px;
}

.reports-management .id-column{
    width: 75px;
    box-sizing: border-box;
}

.reports-management .sub-column{
    width: 30px;
    text-align: center;
}

.reports-management .sub-sub-column{
    width: 30px;
    text-align: right;
}

.reports-management .name-column,
.reports-management .state-column,
.reports-management .time-column {
    min-width: 150px;
    max-width: 150px;
}
.reports-details-modal .username-column,
.reports-details-modal .group-name-column{
    width: 120px;
}
.reports-details-modal .template-name-column{
    width: 150px;
}
.reports-details-modal .lab-id-column,
.reports-details-modal .uptime-column,
.reports-details-modal .avtime-column{
    width: 100px;
}
.reports-details-modal .control-buttons-block{
    margin-top: 20px;
    text-align: right;
}

.select-template-modal .create-column,
.select-template-modal .user-column,
.select-template-modal .version-column{
    width: 95px;
}

.select-template-modal .id-column,
.select-template-modal .version-column,
.select-template-modal .nocheck-column {
    width: 50px;
}

.select-template-modal .name-column {
    width: 150px;
}

.select-template-modal .table-row {
    height: 45px;
}
.MuiPaper-root.MuiDialog-paper.MuiPaper-rounded {
    border: 1px solid rgba(255, 255, 255, 1);
}

.status-total-block {
    float: left;
    padding: 6px 8px;
}
/* redefine pagination (notifier conflict)*/
div.pagination button.MuiFlatPageButton-rootStandard {
    padding-left: 12px;
    padding-right: 12px;
}

div.pagination .MuiFlatPageButton-root {
     min-width: 16px;
 }

div.pagination .MuiButton-colorInherit {
     color: inherit;
 }

div.pagination .MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

div.pagination .MuiButton-text {
     padding: 6px 8px;
 }
div.pagination .MuiButton-textSecondary {
    color: #f50057;
}

/* redefine switches (notifier conflict)*/
div.lab-templates-management .MuiSwitch-colorSecondary.Mui-disabled {
    color: #bdbdbd;
}

div.lab-templates-management .MuiSwitch-colorSecondary.Mui-checked {
    color: #f50057;
}

div.lab-templates-management .MuiSwitch-switchBase.Mui-disabled {
    color: #bdbdbd;
}

div.lab-templates-management .MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
}

div.lab-templates-management .MuiSwitch-switchBase {
    top: 0;
    left: 0;
    color: #fafafa;
    z-index: 1;
    position: absolute;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    padding: 9px;
}

div[role=presentation] .MuiAutocomplete-listbox{
    background-color: rgb(39, 6, 6);
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

div[role=presentation] div[role=menu] {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

div[role=dialog] {
    z-index: 2000 !important;
}

/* Pickers overrides */
div[role=dialog] .MuiPickersDay-daySelected:hover {
    background-color: rgb(255, 107, 102);
}

div[role=dialog] .MuiButton-textPrimary:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.MuiFormControl-root .MuiInput-underline:after {
    border-bottom: 2px solid #fafafa;
}

.time-frame-modal .MuiFormControl-root.MuiTextField-root {
    width: 100px;
    margin-left: 10px;
}

.add-edit-seminar-modals label.MuiFormLabel-root.Mui-focused {
    color: #fff;
}

/* Modal presentation override because of notifier */
div[role=presentation] div.MuiPaper-root {
    background-color: rgb(22, 0,0);
    color: #fff;
}

div[role=dialog].MuiDialog-root div.MuiPaper-root {
    color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #424242;
}

div[role=dialog].MuiDialog-root div.MuiPaper-root .MuiButtonBase-root {
    color: #fff;
}